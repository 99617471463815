main.cariers-page {
  .hero {
    .img-wrapper-bg {
      padding: 150px 0;
      background-image: linear-gradient(0deg, #000627, #132254);
      @include media-breakpoint-down(lg) {
        padding: 70px 0 70px 0;
      }
    }
    .text-over {
      position: relative;
      z-index: 1;
    }
    .tint:after {
      background-color: #3e424b;
      opacity: 0.35;
    }
  }
}
