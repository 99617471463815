main.gallery-page {
  .hero {
    .img-wrapper-bg {
      padding: 70px 0 70px 0;
      background-image: linear-gradient(0deg, #000627, #132254);
    }
    .text-over {
      position: relative;
      z-index: 1;
    }
  }
  .discover {
    .card-columns {
      column-gap: 1.25rem;
      orphans: 1;
      widows: 1;
      column-count: 1;
      @include media-breakpoint-between(md, lg) {
        column-count: 2;
      }
      @include media-breakpoint-up(lg) {
        column-count: 3;
      }
    }
    .card {
      margin-bottom: 1rem;
      overflow: hidden;
      border: 0 solid #00000020;
      @include media-breakpoint-up(sm) {
        display: inline-block;
        width: 100%;
      }
      &:hover {
        img {
          transform: scale(1.2);
        }
        cursor: pointer;
        @include media-breakpoint-up(lg) {
          .card-body {
            bottom: 0;
            background-color: #ffffffe6;
          }
        }
      }
      img {
        transform: scale(1);
        transition: transform 0.45s ease-in-out;
      }
      .card-body {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0;
        height: 3.5rem;
        width: 100%;
        background-color: #fff;
        min-height: 1px;
        padding: 1.35rem;
        @include media-breakpoint-up(lg) {
          height: 100%;
          bottom: -100%;
          transition: bottom 0.15s ease-in-out;
        }
      }
      .card-title {
        font-size: 0.75rem;
        margin: 0;
        text-transform: uppercase;
        font-weight: 800;
        letter-spacing: 0.1em;
        color: $dark;
      }
    }
  }
}
