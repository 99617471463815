main.contact-page {
  .hero {
    // background-color: rgb(171, 217, 247);
    .img-wrapper-bg {
      padding: 100px 0 150px 0;
      background-image: linear-gradient(0deg, #000627, #132254);
    }
    .text-over {
      position: relative;
      z-index: 1;
    }
    .tint:after {
      background-color: #3e424b;
      opacity: 0.4;
    }
  }
  .content-wrapper {
    .aside {
      .social-media {
        .icon-wrapper {
          &:hover {
            background-image: linear-gradient(
              180deg,
              rgba(66, 156, 245, 0),
              rgba(41, 139, 242, 0.3)
            );
          }
        }
      }
      .icon-wrapper {
        display: inline-block;
        width: 40px;
        height: 40px;
        background: linear-gradient();
        border-radius: 50%;
        text-align: center;
        line-height: 40px;
        color: $white;
      }
      .bg {
        background-image: linear-gradient(
          180deg,
          rgba(66, 156, 245, 0),
          rgba(41, 139, 242, 0.3)
        );
        border-color: #2f96f4;
      }
      .title {
        font-size: 12px;
        font-weight: 500;
      }
      .descr {
        font-size: 13px;
      }
    }
  }
}
