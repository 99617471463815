main.homepage {
  .hero {
    .img-wrapper-bg {
      padding: 100px 0 150px 0;
      background-image: linear-gradient(0deg, #000627, #132254);
      .text-over {
        position: relative;
        z-index: 1;
      }
    }
    .tint {
      &:after {
        background-color: #3e424b;
        opacity: 0.35;
      }
    }
  }
  .business {
    position: relative;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
      min-height: 565px;
    }
    .second-col {
      @include media-breakpoint-up(lg) {
        position: relative;
        top: 100px;
      }
    }
    .third-col {
      @include media-breakpoint-up(lg) {
        position: relative;
        top: 200px;
      }
    }
    .card {
      border: none;
      border-radius: 0;
      box-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.45);
      .img-wrapper-bg {
        padding: 25% 0;
        @include media-breakpoint-between(lg, xl) {
          padding: 33% 0;
        }
      }
      .text-over {
        position: absolute;
        z-index: 2;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        h5 {
          @include media-breakpoint-down(sm) {
            font-size: 18px;
          }
          @include media-breakpoint-between(lg, xl) {
            font-size: 18px;
          }
        }
        .descr {
          font-size: 15px;
          @include media-breakpoint-down(sm) {
            font-size: 13px;
          }
          @include media-breakpoint-between(lg, xl) {
            font-size: 13px;
          }
        }
      }
    }
  }
  .content-wrapper {
    .aside {
      .bg {
        background-image: linear-gradient(
          180deg,
          rgba(66, 156, 245, 0),
          rgba(41, 139, 242, 0.3)
        );
        border-color: #2f96f4;
      }
      .title {
        font-size: 12px;
        font-weight: 500;
      }
      .descr {
        font-size: 13px;
      }
    }
  }
  .carousel-wrapper {
    .card {
      border: transparent;
      overflow: hidden;
      border-radius: 0;
      box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
      transition: box-shadow 0.7s ease-in-out;
      .img-wrapper-bg {
        padding: 30% 0;
        overflow: hidden;
        img {
          transform: scale(1);
          transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }
    &.partners {
      img {
        height: 70px;
        object-fit: contain;
      }
    }
  }
  .animation-wrapper {
    position: relative;
    overflow: hidden;
    height: 60px;
    .car {
      position: absolute;
      left: 0;
      height: 60px;
      bottom: -7px;
      animation: moving 13s linear infinite;
      @include media-breakpoint-down(md) {
        animation: moving 7s linear infinite;
      }
    }
    @keyframes moving {
      from {
        left: -165px;
      }
      to {
        left: 100%;
      }
    }
  }
}
