header {
  position: relative;
  @include media-breakpoint-down(md) {
    border-bottom: 10px solid $danger;
  }
  .navbar-light {
    .nav-wrapper {
      @include media-breakpoint-down(md) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }
    }
    .navbar-brand {
      img.logo-desktop {
        height: 60px;
      }
      img.logo-mobile {
        height: 40px;
      }
    }
    .navbar-toggler {
      background-color: $primary;
      //  background-color: $danger;
      .navbar-toggler-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='%23800000' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
      }
      // .navbar-toggler-icon {
      //   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='%23132254' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
      // }
    }
    .navbar-collapse {
      @include media-breakpoint-up(md) {
        flex-grow: 0;
      }
    }
    .nav-item {
      .nav-link {
        color: $primary;
        font-weight: 500;
      }
    }
  }
  .bosh-logo {
    height: 40px;
    animation: rotation 4s infinite linear;
  }
  @keyframes rotation {
    100% {
      transform: rotatey(360deg);
    }
  }
  .navbar-red {
    background: $danger;
    flex-wrap: nowrap;
    justify-content: flex-start;
    .nav {
      .nav-item {
        .nav-link {
          color: $white;
          padding: 12px 20px;
          font-weight: 600;
          &:hover {
            color: $white;
          }
        }
      }
    }
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
  .top-nav-left,
  .top-nav-right {
    position: absolute;
    z-index: 99;
    height: 86px;
    top: 0;
    .nav-link {
      color: $white;
      height: 100%;
      line-height: 68px;
      &:hover {
        background-image: linear-gradient(
          180deg,
          rgba(66, 156, 245, 0),
          rgba(41, 139, 242, 0.3)
        );
        border-color: #2f96f4;
        border-color: red;
      }
      i {
        font-size: 22px;
      }
    }
  }
  .top-nav-left {
    left: 0;
    li {
      position: relative;
      &:nth-of-type(1) {
        left: -1px;
      }
      &:nth-of-type(2) {
        left: -2px;
      }
      &:nth-of-type(3) {
        left: -3px;
      }
    }
  }
  .top-nav-right {
    right: 0;
    li {
      position: relative;
      &:nth-of-type(3) {
        right: -1px;
      }
      &:nth-of-type(2) {
        right: -2px;
      }
      &:nth-of-type(1) {
        right: -3px;
      }
    }
  }
  .triangle {
    position: relative;
    width: 150px;
    height: 86px;
    &.left {
      clip-path: polygon(0 0, 0 100%, 100% 0);
    }
    &.right {
      clip-path: polygon(0 0, 100% 100%, 100% 0);
    }
  }
  .dropdown {
    .dropdown-menu {
      background-color: $danger;
      border-color: $danger;
      @include media-breakpoint-up(md) {
        &.show {
          transform: translate(0px, 45px) !important;
        }
      }
      .dropdown-item {
        color: $white;
        font-size: 15px;
        &:hover,
        &:focus {
          background-color: $danger;
        }
      }
    }
  }
}
