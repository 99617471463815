main.terms {
  .hero {
    .img-wrapper-bg {
      padding: 70px 0 70px 0;
      background-image: linear-gradient(0deg, #000627, #132254);
    }
    .text-over {
      position: relative;
      z-index: 1;
    }
  }
}
