main.services {
  .hero {
    .img-wrapper-bg {
      padding: 70px 0 70px 0;
      background-image: linear-gradient(0deg, #000627, #132254);
    }
    .text-over {
      position: relative;
      z-index: 1;
    }
  }
  .content {
    font-weight: 500;
    color: $primary;
    .card {
      border: none;
      border-radius: 0;
    }
    .pills-wrapper {
      background-color: $primary;
      border-bottom: 4px solid $danger;
    }
    .nav-pills {
      .nav-link {
        font-weight: 500;
        color: $white;
        padding: 20px 16px;
        border-radius: 0;
        font-size: 14px;
        i {
          margin-right: 8px;
        }
        @include media-breakpoint-up(lg) {
          &:hover {
            background-color: $primary;
            background-image: linear-gradient(
              180deg,
              rgba(66, 156, 245, 0),
              rgba(41, 139, 242, 0.3)
            );
          }
          &.active {
            background-color: $primary;
            background-image: linear-gradient(
              180deg,
              rgba(66, 156, 245, 0),
              rgba(41, 139, 242, 0.3)
            );
          }
        }
      }
    }
    .tab-content {
      .btn {
        white-space: nowrap;
      }
      @include media-breakpoint-down(sm) {
        font-size: 14px;
      }
    }
    .prices {
      .bg-dark {
        border-bottom: 4px solid $danger;
        font-size: 14px;
        padding: 20px 16px;
      }
      .table {
        @include media-breakpoint-down(sm) {
          font-size: 14px;
        }
      }
    }
    ul {
      list-style-type: none;
    }
  }
}
