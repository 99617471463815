main.damage-page {
  // background-color: rgb(171, 217, 247);
  .hero {
    .img-wrapper-bg {
      padding: 80px 0 70px 0;
      background-image: linear-gradient(0deg, #000627, #132254);
    }
    .text-over {
      position: relative;
      z-index: 1;
    }
    .tint:after {
      background-color: #3e424b;
      opacity: 0.4;
    }
  }
  .content-wrapper {
    .aside {
      .bg {
        background-image: linear-gradient(
          180deg,
          rgba(66, 156, 245, 0),
          rgba(41, 139, 242, 0.3)
        );
        border-color: #2f96f4;
      }
      .title {
        font-size: 12px;
        font-weight: 500;
      }
      .descr {
        font-size: 13px;
      }
    }
  }
  .animation {
    position: relative;
    overflow: hidden;
    height: 125px;
    width: 100%;
    .accident {
      position: absolute;
      left: 0;
      right: 0;
      height: 125px;
      bottom: -2px;
      width: 100%;
    }
  }
}
