// setup bs variables
$headings-font-weight: 500;
$display-font-weight: 500;
$btn-font-weight: 700;
$dark: #131f4a;
$primary: #132254;
$danger: #800000;
$body-color: $dark;
$transition: all 0.5s linear;
$scrollbarBG: #cfd8dc;
$thumbBG: #90a4ae;
@import "../../node_modules/bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@300;400;500&display=swap");

body {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  background-image: url(images/2747_3.png);
  background-color: #f7f8f8;
  background-position: 0 0;
  background-repeat: repeat;
  background-size: 600px 600px;
  scrollbar-width: thin;
  scrollbar-color: $thumbBG $scrollbarBG;
}
body::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}
body::-webkit-scrollbar-thumb {
  height: 15%;
  background-image: -webkit-gradient(
    linear,
    0% 0%,
    20% 100%,
    from($dark),
    to($dark),
    color-stop(0.5, #466db4)
  );
}
body::-webkit-scrollbar-track {
  background-color: $dark;
}
main {
  min-height: calc(100vh - 438px);
  margin-top: 134px;
  @include media-breakpoint-down(md) {
    margin-top: 76px;
  }
}
.cinat {
  font-family: "Kanit", sans-serif;
}
a {
  text-decoration: none;
}
.tint:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #012250;
  opacity: 0.7;
  width: 100%;
  height: 100%;
}
.img-wrapper-bg {
  position: relative;
  img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.carousel-wrapper {
  .owl-carousel {
    .owl-stage {
      padding: 20px 0;
    }
    .owl-nav {
      .owl-prev,
      .owl-next {
        position: absolute;
        top: 45%;
        width: 40px;
        height: 40px;
        border: none;
        border-radius: 50%;
        background-color: $light;
        transition: $transition;
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
        &:hover {
          background-color: $danger;
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
        }
        span {
          position: absolute;
          top: 0;
          right: 11px;
          font-size: 50px;
          line-height: 30px;
          text-align: center;
        }
      }
      .owl-prev {
        left: 0;
        span {
          right: auto;
          left: 10px;
        }
      }
      .owl-next {
        right: 0;
      }
    }
    .owl-dots {
      .owl-dot {
        background-color: transparent;
        border: none;
        &.active {
          span {
            background-color: $danger;
          }
        }
      }
    }
  }
}
@import "../components/header/index";
@import "../components/footer/index";
