footer {
    border-top: 5px solid $danger;
    color: $white;
    .navbar-brand {
        img {
            height: 30px;
        }
    }
    .text-info {
        font-size: 13px;
        text-decoration: underline;
    }
    .social-media {
        .icon-wrapper {
            display: inline-block;
            width: 40px;
            height: 40px;
            background: linear-gradient();
            border-radius: 50%;
            text-align: center;
            line-height: 40px;
            color: $info;
            &:hover {
                background-image: linear-gradient( 180deg, rgba(66, 156, 245, 0), rgba(41, 139, 242, 0.3));
            }
        }
    }
    .scroll-view {
        position: fixed;
        right: 30px;
        bottom: 13px;
        z-index: 9999;
    }
    .links {
        color: $white;
        font-size: 14px;
        a {
            color: $white;
            display: block;
            padding: 8px 16px 8px 0;
            &:hover {
                COLOR: #0dcaf0;
            }
        }
    }
}